import { useLocation } from '@reach/router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useImageQuery from '../../hooks/useImageQuery';
import { MEDIA_TYPE } from '../../utils/constants';
import Carousel from '../Carousel';
import { Container } from '../Grid';
import Image from '../Image';
import './styles.scss';

const Hero = ({ mediaSource, mediaType, textOverlay, className, children }) => {
  const isVideo = mediaType && Object.values(MEDIA_TYPE).includes(mediaType);
  const isMultiImage = Array.isArray(mediaSource);

  const { pathname: currentPath } = useLocation();
  const isHome = currentPath === '/';
  const { findImageNodeByPath, findImageNodeByFilename } = useImageQuery();
  let imageNode = findImageNodeByPath(mediaSource);
  if (!imageNode) {
    imageNode = findImageNodeByFilename(mediaSource);
  }

  if (isVideo) {
    return (
      <Container fullWidth noPadding className={classNames('hero', className)}>
        <video autoPlay loop muted className="hero__video">
          <source src={mediaSource} type={mediaType}></source>
        </video>
        <Container fullWidth className="hero__content">
          <div className="hero__overlay">{textOverlay}</div>
          {isHome ? (
            // <Image
            //   filename="harmony-reverse-logo.svg"
            //   className="hero__content__orange-logo"
            // />
            ''
          ) : (
            <Image
              filename="logo-header-white-small.png"
              className="hero__content__white-logo"
            />
          )}
          {children}
        </Container>
      </Container>
    );
  } else if (isMultiImage) {
    return (
      <div className={classNames('hero', className)}>
        <Carousel
          headingClassOverride={'mb-0'}
          imageContainerClassOverride={'mb-0'}
          slideClassOverride={'mb-0'}
          settings={{ fade: true }}
          slides={mediaSource}
        />
        <h2 className="hero__overlay">{textOverlay}</h2>
      </div>
    );
  } else {
    return (
      <Container fullWidth noPadding className={classNames('hero', className)}>
        <Image filename={mediaSource} className="hero__img" />
        <Container fullWidth className="hero__content">
          <h2 className="hero__overlay">{textOverlay}</h2>
          {isHome ? (
            // <Image
            //   filename="harmony-reverse-logo.svg"
            //   className="hero__content__orange-logo"
            // />
            ''
          ) : (
            <Image
              filename="logo-header-white-small.png"
              className="hero__content__white-logo"
            />
          )}
        </Container>
      </Container>
    );
  }
};

Hero.defaultProps = {
  textOverlay: null,
  className: null,
};

Hero.propTypes = {
  /** A path to video or a filename for an image (using the Image Component)
   * or an array of objects for multi image slider
   */
  mediaSource: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  ]).isRequired,
  /** The type used for the video */
  mediaType: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
  /** Text overlay for the image/video. The hero-text-overlay-container-defaults mixin
   * contains the default CSS for a left side text overlay.
   */
  textOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** A custom class name */
  className: PropTypes.string,
  /** Elements being passed to the component */
  children: PropTypes.node,
};

export default Hero;
