import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from '../Grid';
import Image from '../Image';
import './styles.scss';

const Quote = ({ text, source, backgroundImage, contentWidth, className }) => {
  return (
    <Container fullWidth noPadding className={classNames('quote', className)}>
      <Image
        className="quote__background-image"
        filename={backgroundImage}
        style={{ objectFit: 'cover' }}
      />
      <div className="quote__content" style={{ maxWidth: contentWidth }}>
        <h2 className="quote__content--text">“{text}”</h2>
        <h3 className="quote__content--source"> —{source}</h3>
      </div>
    </Container>
  );
};

Quote.defaultProps = {
  content: null,
  contentWidth: '65%',
};

Quote.propTypes = {
  /** The tquote to be displayed */
  text: PropTypes.string.isRequired,
  /** The source of the quote */
  source: PropTypes.string,
  /** The image to be used as background */
  backgroundImage: PropTypes.string.isRequired,
  /** Max width for the content section */
  contentWidth: PropTypes.string,
  /** Custom class */
  className: PropTypes.string,
};

export default Quote;
